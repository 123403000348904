<template>
    <div>
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">{{$t("Комментарии")}}</div>
                            <div v-if="dataSource" class="dialogWrapper">
                                <template v-for="(comment, index) in dataSource">
                                    <div :key="index" :class="[getCommentClass(comment)]">
                                        <div class="d-title">
                                            <template v-if="comment.IsPortal">{{comment.AuthorName}} </template>
                                            <v-workplace-chip v-else :name="comment.AuthorName" :id="comment.AuthorId"/>                                            
                                            ({{comment.EnterpriseName}})
                                        </div>
                                        <div class="d-content">{{comment.CommentText}}</div>
                                        <div class="d-flex justify-end">{{formatDate(comment.CommentDate, 'DD.MM.YYYY HH:mm')}}</div>
                                    </div>
                                </template>
                            </div>
                            <v-row class="full-width-row" dense>
                                <v-col  >
                                    <v-textarea
                                        v-model="comment"
                                        rows="1"
                                        row-height="16"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="requiredRule"
                                        class="oneRowStartTextarea"
                                        auto-grow
                                    >
                                    </v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="auto" class="d-flex align-center">
                                    <div 
                                        class="d-send-btn"                                        
                                        @click="addComment"
                                        v-tooltip.left-center='$t("Добавить_комментарий")'
                                    >
                                        <i class="far fa-comment-dots"></i>
                                    </div> 
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sys from "@/services/system";
import i18n from '@/i18n'

export default {
    name: "iqala-comments-tab",
    data() {
        return {
            comment: null
        }
    },
    computed: {
        ...mapGetters('iqalacomments', {dataSource: 'getDataSource', isPending: 'getIsPending'}),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
    },
    methods: {
        ...mapActions('iqalacomments', ['updateDataSource', 'setIsActive']),
        ...mapActions('actionsource', ['IQALAAddCommentFromTab']),
        async update() {            
            await this.updateDataSource();
        },
        getCommentClass(comment) {
            return comment.AuthorId == this.$store.getters['auth/getUserInfo'].workplaceId ? 'dialog dialog-2 dialog-50' : 'dialog dialog-1 dialog-50';
        },
        formatDate: sys.dateFormat,
        async addComment() {
            let validate_result = this.$refs.form.validate();
            if(validate_result){
                await this.IQALAAddCommentFromTab(this.comment);
                this.comment = null;       
            }else{
                this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
            }
        }
    },
    created(){
        this.setIsActive(true);
        this.update();
    },
    destroyed() {
        this.setIsActive(false);
    }
}
</script>
